body {
  @apply text-blue-900 antialiased font-brown;
}

.section {
  @apply py-20;
}

.scrolling-padding-top {
  /**
  * always consider header height and document button when perform anchor scrolling
  */
  scroll-padding-top: 10rem;
}

@media only screen and (min-width: 1024px) {
  .scrolling-padding-top {
    /**
    * always consider header height when perform anchor scrolling
    */
    scroll-padding-top: 6rem;
  }
}
