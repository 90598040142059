.table-of-content-indicator::before {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: 0;
  bottom: 0;
  width: 3px;
  transform: translate(-70%, 0);

  @apply bg-green-400;
}

.table-of-content-link {
  @apply pt-3;
  @apply pb-0;
  @apply text-sm;
  @apply pl-6;
  @apply flex-1;
  @apply w-full;
  @apply items-center;
  @apply space-x-2;
  @apply transition;
  @apply duration-75;
  @apply leading-tight;
  @apply text-grey-700;
  @apply table;
}

.table-of-content-link:hover,
.table-of-content-link:focus,
.table-of-content-link-active {
  @apply text-blue-900;
}

.table-of-content-heading {
  padding-bottom: 0.065rem;

  @apply pl-6;
  @apply text-sm;
  @apply leading-7;
  @apply text-blue-900;
}

.table-of-content-heading.table-of-content-indicator::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0.065rem;
  width: 3px;
  transform: translate(-70%, 0);

  @apply bg-green-400;
}

.table-of-content-indicatorbar {
  margin-left: 2px;

  @apply relative;
  @apply border-l;
  @apply border-grey-200;
}
