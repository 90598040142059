.code-snippet {
  @apply relative mb-xl;
}

.code-snippet pre {
  @apply text-sm leading-tight;

  padding: 3.5rem 1.125rem 1rem !important;
  border-radius: 0.6em !important;
}

.code-snippet pre,
.code-snippet pre code {
  font-family:
    Courier,
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New",
    monospace,
    serif !important;
}

.inline-code code {
  @apply text-sm leading-normal text-green-900 dark:text-green-400 p-xs m-0 bg-green-400 bg-opacity-10;
}
