/* stylelint-disable selector-class-pattern */
.api h2,
.api h5 {
  @apply mb-m text-blue-900 dark:text-grey-20 inline-flex items-center;
}

.api h2 {
  @apply mt-xl text-3xl font-bold leading-extra-tight;
}

.api table {
  overflow-wrap: anywhere;

  @apply mb-0 !important;
}

.api h1 a,
.api h2 a,
.api h5 a {
  @apply ml-3 order-4;
}

.api h1 span,
.api h2 span,
.api h5 span {
  @apply ml-1 order-3;
}

.api h1:hover a::after,
.api h2:hover a::after,
.api h5:hover a::after {
  @apply opacity-100;
}

.api h1 a::before,
.api h2 a::before,
.api h5 a::before {
  display: none;
}

.api h1 a::after,
.api h2 a::after,
.api h5 a::after {
  content: "";
  width: 15px;
  height: 15px;
  background-size: contain;
  background-image: url("../assets/images/anchor-link-icon.svg");
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
}

.api h5 {
  @apply mr-xs;
}

.api h5 span {
  @apply text-blue-900 dark:text-grey-20;
}

.api code {
  @apply font-code border-0 text-sm leading-normal text-green-900 dark:text-api-code-green bg-green-400 bg-opacity-10;
}

.api .menu-content {
  @apply pt-20 pl-4 border-r border-grey-700 dark:border-grey-300;
}

.api h1 {
  @apply text-2xl font-bold leading-tight my-m inline-flex items-center;
}

.api p {
  @apply text-base font-normal leading-loose;
}

.api .api-content a {
  @apply text-external-link;
}

.api .api-info {
  @apply px-m lg:px-xxl;

  padding-top: 70px !important;
}

.api div[data-section-id]:not(div[data-section-id^="tag"]) > div:first-child > div:first-child {
  @apply px-m lg:px-xxl pb-s lg:pb-0;
}

.api div[data-section-id] .redoc-markdown {
  @apply px-m lg:px-xxl;
}

.api div[data-section-id] .redoc-markdown strong {
  @apply text-blue-900 dark:text-grey-20;
}

.api div[data-section-id] > div {
  @apply p-0;
}

.api div[data-section-id^="tag"] > div:nth-child(2) > div:first-child > div:first-child {
  @apply px-m lg:px-xxl;
}

.api div[data-section-id^="tag"] > div:first-child > div:first-child {
  @apply px-m lg:px-xxl pb-s lg:pb-0;
}

.api div[data-section-id] {
  @apply xl:pb-m;
}

.api div[data-section-id] button > :not(div) {
  @apply rounded-sm;
}

.api div[data-section-id] button span {
  @apply ml-1;
}

.api .menu-content li[data-item-id] label.-depth1 {
  @apply p-0 mr-4 mb-2.5 lg:px-2 py-1 w-10/12 dark:hover:bg-grey-700 hover:bg-grey-40 hover:rounded-sm hover:text-blue-900 dark:hover:text-grey-40;
}

.api .menu-content li[data-item-id] .-depth1 span {
  @apply text-sm w-full leading-tight p-0 mx-2 table;
}

.api .menu-content li[data-item-id] .-depth2 {
  @apply my-3 mr-4 ml-1 py-0 dark:hover:bg-grey-700 hover:bg-grey-40 hover:rounded-sm;
}

.api .menu-content li[data-item-id] .active {
  @apply dark:bg-grey-700 bg-grey-40 rounded-sm;
}

.api .menu-content li[data-item-id] .-depth2 span.operation-type {
  @apply mr-2 mt-1 text-blue-900;
}

.api .menu-content li[data-item-id] .-depth2 span.operation-type.hook {
  @apply bg-grey-40 dark:bg-grey-700;
}

.api .menu-content li[data-item-id] .-depth2 span:last-child {
  @apply dark:hover:bg-grey-700 hover:bg-grey-40 hover:rounded-sm hover:text-blue-900 dark:hover:text-grey-40;
}

.api table th,
.api table tr,
.api table td {
  @apply bg-transparent !important;
}

.api .dropdown {
  @apply bg-grey-100 bg-opacity-15 !important;
}

.api .dropdown-selector {
  @apply pl-xxs !important;
}

.api .dropdown-selector-content {
  @apply bg-transparent rounded-md text-blue-900 left-0 p-0 dark:bg-code border dark:text-grey-40 dark:border-grey-700 divide-y divide-grey-200 border-solid !important;
}

.api .dropdown-selector-content .dropdown-option {
  @apply bg-white hover:bg-grey-40 hover:text-blue-900 !important;
}

.api .dropdown-selector-content .dropdown-option.selected {
  @apply bg-grey-40 text-blue-900 !important;
}

.api .dropdown-selector span,
.api .dropdown-selector .dropdown-arrow {
  @apply border-t-blue-900 dark:text-grey-40 dark:border-t-grey-40 !important;
}

/* rightPanel */
.api div[data-section-id^="operation"] > div:first-child > div:nth-child(2),
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) {
  @apply px-m lg:px-base pt-xl;
}

.api div[aria-hidden] {
  @apply dark:bg-code border dark:border-grey-700;
}

.api div[aria-hidden] p {
  @apply dark:text-white;
}

.api div[aria-hidden] div[role="button"] > div {
  @apply bg-transparent dark:text-grey-40 !important;
}

.api div[data-section-id^="operation"] > div:first-child h3,
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) h3 {
  @apply py-0 my-base;
}

.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) > div > div:not(div[data-rttabs="true"]) {
  @apply bg-api-code;
}

.api div[data-section-id^="operation"] .react-tabs__tab-panel,
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) .react-tabs__tab-panel {
  @apply pt-base bg-transparent;
}

.api div[data-section-id^="operation"] .react-tabs__tab-panel--selected,
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) .react-tabs__tab-panel--selected {
  @apply bg-api-code;
}

.api div[data-section-id^="operation"] .react-tabs__tab-panel > div {
  @apply px-base;
}

.api div[data-section-id^="operation"] .react-tabs__tab-panel button,
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) button {
  @apply text-sm leading-loose text-blue-400 font-code;
}

.api div[data-section-id^="operation"] .react-tabs__tab-list,
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) .react-tabs__tab-list {
  @apply pb-base;
}

.api div[data-section-id^="operation"] .react-tabs__tab-list .tab-success,
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) .react-tabs__tab-list .tab-success {
  @apply text-green-400;
}

.api div[data-section-id^="operation"] .react-tabs__tab-list .tab-error,
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) .react-tabs__tab-list .tab-error {
  color: #ff665b;
}

.api div[data-section-id^="operation"] .react-tabs__tab,
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) .react-tabs__tab {
  @apply bg-grey-200 text-blue-900 mr-2;
}

.api div[data-section-id^="operation"] .react-tabs__tab--selected,
.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) .react-tabs__tab--selected {
  @apply text-blue-900 bg-white;
}

.api div[data-section-id^="operation"] .redoc-json {
  @apply bg-api-code;
}

.api div[data-section-id^="tag"] > div:first-child > div:nth-child(2) {
  @apply pt-xl;
}

.api div[data-section-id^="tag"] > div:nth-child(2) > div:first-child > div:nth-child(2) button {
  @apply text-sm leading-loose text-blue-400 font-code bg-transparent hover:bg-transparent;
}

.api .api-info::before {
  @apply text-5xl text-blue-900 dark:text-white font-bold leading-extra-tight md:text-3xl;
}

.api-info h1:first-of-type {
  @apply text-3xl font-bold leading-extra-snug inline-block;
}

.api-info ul > li {
  list-style-type: disc;
}

.api .api-info a[download] {
  @apply block border border-grey-300 py-2 px-6 rounded-full text-center no-underline bg-grey-20 text-grey-800 relative;

  width: 150px;
  margin: 20px 0 50px;
  transition: background-color 300ms;
  border-radius: 99px;
  padding-left: 44px !important;
}

.api .api-info a[download]:hover,
.api .api-info a[download]:focus {
  @apply bg-grey-40;
}

.api .api-info a[download]::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-image: url("../assets/images/download-icon.svg");
  background-size: 16px;
  left: 18px;
  top: 15px;
}

.dark .api .api-info a[download]::before {
  background-image: url("../assets/images/download-dark.svg");
}

.dark .api .api-info a[download] {
  background: rgb(250 250 251 / 10%);

  @apply bg-white/10 text-white border border-grey-20;
}

.dark .api .api-info a[download]:hover,
.dark .api .api-info a[download]:focus {
  @apply bg-white/20;
}

.api label[role="menuitem"] {
  @apply text-sm normal-case !important;
}

.api .operation-type {
  background: #9451f3 !important;

  @apply text-white rounded-[2px] !important;
}

.api .operation-type.put {
  background: #d96d30 !important;
}

.api .operation-type.get {
  background: #015fd1 !important;
}

.api .operation-type.post {
  background: #018b6e !important;
}

.api .operation-type.delete {
  background: #ee8b80 !important;
}

.api li[data-rttab="true"] {
  border-radius: 12px !important;
  min-width: auto !important;
  padding: 8px 26px !important;
  margin-right: 12px !important;
  border: none !important;
}

.api .api-content::after {
  content: "";
  display: block;
  clear: both;
  background-color: #38394d;
  position: absolute;
  right: -50vw;
  width: 50vw;
  top: 0;
  bottom: 0;
}

.api .api-content {
  overflow: visible !important;
}
