.btn,
.cms-content .btn,
.cms-content a.btn {
  @apply inline-block bg-green-900 text-green-400 py-2 px-6 rounded-full text-center no-underline;

  transition: background-color 300ms;
}

.btn-secondary,
.cms-content a.btn-secondary {
  @apply inline-block bg-white text-blue-900 hover:bg-green-100 dark:hover:bg-green-400 hover:text-blue-900 active:bg-blue-900 active:text-green-400 border border-green-400;

  transition: background-color 300ms;
}
