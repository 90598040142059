#onetrust-consent-sdk #onetrust-banner-sdk {
  @apply bg-green-900 text-white !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk a[href] {
  @apply text-green-400 no-underline !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk a[href]:hover {
  @apply underline !important;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-consent-sdk #onetrust-pc-btn-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler {
  @apply inline-block bg-green-900 text-green-400 py-3 px-6 rounded-full border-green-400 font-normal !important;

  transition: background-color 300ms, border 300ms;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler:hover,
#onetrust-banner-sdk #onetrust-reject-all-handler:hover,
#onetrust-consent-sdk #onetrust-pc-btn-handler:hover,
#accept-recommended-btn-handler:hover,
.save-preference-btn-handler:hover {
  @apply bg-blue-900 opacity-100 !important;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler:focus,
#onetrust-banner-sdk #onetrust-reject-all-handler:focus,
#onetrust-consent-sdk #onetrust-pc-btn-handler:focus,
#accept-recommended-btn-handler:focus,
.save-preference-btn-handler:focus {
  outline: 1px dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  opacity: 1 !important;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  @apply border-0 text-white px-0 py-1 text-base !important;

  line-height: inherit !important;
}

#ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.optanon-show-settings:hover {
  @apply bg-green-900 text-green-400 !important;
}

@media (min-width: 550px) {
  #onetrust-banner-sdk .ot-sdk-three.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-three.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-three.ot-sdk-columns {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1024px) {
  #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
    width: 40% !important;
  }
}
