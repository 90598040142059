@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./base.css";
@import "./typography.css";
@import "./cms-content.css";
@import "./code-snippet.css";
@import "./button.css";
@import "./table.css";
@import "./table-of-content.css";
@import "./api.css";
@import "./one-trust.css";

:root {
  --collapse-height: 0;
}

@layer base {
  @font-face {
    font-family: Brown;
    font-weight: normal;
    src:
      url("../assets/fonts/BrownLLWeb-Regular.woff2") format("woff2"),
      url("../assets/fonts/BrownLLWeb-Regular.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: Brown-Bold;
    font-weight: bold;
    src:
      url("../assets/fonts/BrownLLWeb-Bold.woff2") format("woff2"),
      url("../assets/fonts/BrownLLWeb-Bold.woff") format("woff");
    font-display: swap;
  }
}

@layer components {
  .collapse-content {
    max-height: var(--collapse-height, 0);
  }
}
