.h1 {
  /* font-size: 52px; line-height: 120% (62px); */
  @apply text-5xl font-bold leading-extra-tight md:text-3xl;
}

.h2 {
  /* font-size: 38px; line-height: 120% (46px); */
  @apply text-3xl font-bold leading-extra-tight;
}

.h3 {
  /* font-size: 32px; line-height: 125% (40px); */
  @apply text-2xl font-normal leading-tight;
}

.h4 {
  /* font-size: 28px; line-height: 150% (42px); */
  @apply text-xl font-normal leading-normal;
}

.h5 {
  /* font-size: 20px; line-height: 130% (26px); */
  @apply text-lg font-normal leading-extra-snug;
}

.h6 {
  /* font-size: 32px; line-height: 140% (45px); */
  @apply text-2xl font-bold leading-snug;
}

.text {
  /* font-size: 16px; line-height: 150% (24px); */
  @apply text-base font-normal leading-normal;
}

.paragraph-small {
  /* font-size: 14px; line-height: 125% (18px); */
  @apply text-sm font-normal leading-tight;
}

.button-text {
  /* font-size: 12px; line-height: 150% (16px); */
  @apply text-lg font-normal leading-normal;
}

.header-1 {
  /* font-size: 48px; line-height: 120% (58px); */
  @apply text-4xl font-normal leading-extra-tight;
}

.header-2 {
  /* font-size: 32px; line-height: 120% (38px); */
  @apply text-2xl font-normal leading-extra-tight;
}

.header-3 {
  /* font-size: 18px; line-height: 150% (27px); */
  @apply text-md font-normal leading-normal;
}

.copyright {
  /* font-size: 13px; line-height: 125% (16px); */
  @apply text-xs font-normal leading-tight;
}
