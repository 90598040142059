.cms-content {
  margin: 0;
  background-color: inherit;
  word-wrap: break-word;

  @apply text-base font-normal leading-loose text-grey-700 dark:text-grey-20 mb-14;
}

.cms-content > p {
  @apply mb-s;
}

.cms-content details,
.cms-content figcaption,
.cms-content figure {
  display: block;
}

.cms-content summary {
  display: list-item;
}

.cms-content a {
  @apply text-blue-700 no-underline bg-transparent;
}

.cms-content a:active,
.cms-content a:hover {
  outline-width: 0;
}

.cms-content abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

.cms-content b,
.cms-content strong {
  font-weight: 600;
}

.cms-content mark {
  background-color: #ff0;
  color: #24292f;
}

.cms-content small {
  font-size: 90%;
}

.cms-content sub,
.cms-content sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.cms-content sub {
  bottom: -0.25em;
}

.cms-content sup {
  top: -0.5em;
}

.cms-content img {
  border-style: none;
  max-width: 100%;
  box-sizing: content-box;
  background-color: #fff;
}

.cms-content code,
.cms-content kbd,
.cms-content pre,
.cms-content samp {
  font-family:
    Courier,
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New",
    monospace,
    serif;
  font-size: 1em;
}

.cms-content figure {
  margin: 1em 40px;
}

.cms-content hr {
  @apply box-content bg-transparent overflow-hidden border-grey-200 dark:border-grey-700 my-l;
}

.cms-content a:hover {
  text-decoration: underline;
}

.cms-content hr::before {
  display: table;
  content: "";
}

.cms-content hr::after {
  display: table;
  clear: both;
  content: "";
}

.cms-content details summary {
  cursor: pointer;
}

.cms-content kbd {
  display: inline-block;
  padding: 3px 5px;
  font: ui-monospace, monospace;
  line-height: 10px;
  color: #24292f;
  vertical-align: middle;
  background-color: #f6f8fa;
  border: solid 1px rgb(175 184 193 / 20%);
  border-bottom-color: rgb(175 184 193 / 20%);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 rgb(175 184 193 / 20%);
}

.cms-content > h1,
.cms-content > h2,
.cms-content > h3,
.cms-content > h4,
.cms-content > h5,
.cms-content > h6 {
  @apply mt-xl mb-m text-blue-900 dark:text-grey-20;
}

.admonition .admonition-header {
  @apply mb-s;
}

.feature-list .feature-list-header {
  @apply mb-base;
}

.cms-content blockquote {
  @apply bg-opacity-15 bg-grey-100 tracking-extra-tight border-b-5 border-green-200 py-m pr-l pl-7 mb-xl;
}

.cms-content blockquote p {
  @apply text-blue-900 dark:text-grey-40;
}

.cms-content blockquote:empty {
  @apply hidden;
}

.cms-content ul,
.cms-content ol {
  @apply my-0 pl-xs ml-base;
}

.cms-content ul {
  @apply list-disc text-grey-700 dark:text-grey-20;
}

.cms-content ol {
  @apply list-decimal text-grey-700 dark:text-grey-20;
}

.cms-content ul p,
.cms-content ol p {
  @apply text-base leading-normal dark:text-grey-20;
}

.cms-content dd {
  margin-left: 0;
}

.cms-content > *:first-child {
  margin-top: 0 !important;
}

.cms-content > *:last-child {
  margin-bottom: 0 !important;
}

.cms-content a:not([href]) {
  color: inherit;
  text-decoration: none;
}

.cms-content blockquote > :first-child {
  margin-top: 0;
}

.cms-content blockquote > :last-child {
  margin-bottom: 0;
}

.cms-content ul.no-list,
.cms-content ol.no-list {
  padding: 0;
  list-style-type: none;
}

.cms-content ol[type="1"] {
  list-style-type: decimal;
}

.cms-content ol[type="a"] {
  list-style-type: lower-alpha;
}

.cms-content ol[type="i"] {
  list-style-type: lower-roman;
}

.cms-content div > ol:not([type]) {
  list-style-type: decimal;
}

.cms-content ul ul,
.cms-content ul ol,
.cms-content ol ol,
.cms-content ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

.cms-content li > p {
  margin-top: 16px;
}

.cms-content li + li {
  margin-top: 0.25em;
}

.cms-content dl {
  padding: 0;
}

.cms-content dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}

.cms-content dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

.cms-content code br,
.cms-content tt br {
  display: none;
}

.cms-content del code {
  text-decoration: inherit;
}

.cms-content pre > code {
  padding: 0;
  margin: 0;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}

.cms-content pre code {
  display: inline;
  font-size: 100%;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}
